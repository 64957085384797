$greyDark:#040404;
$greyLight: #131313;
$greyLighter: #282828;
$greyText: #b3b3b3;
$greenMain: #1db954;

*{
  box-sizing:border-box;
}

.App {
  height: calc(100vh - 90px);
  background: $greyDark;
  display: flex;
}

/* Mobile styles (adjust max-width as needed) */
@media (max-width: 768px) { 
  .App {
    padding: 0px;
  }

  .controls {
    flex-direction: column;
    text-align: center;
  }

  .preview {
    margin-bottom: 20px;
  }

  .signup-btn button {
    //width: 100%;
  }
}

.navBar{
  width: 343px;
  height: 100%;
  background: $greyDark;
  padding:4px;

  .navl{
    background: $greyLight;
    margin: 3px;
    border: 2px solid $greyLight;
    border-radius: 10px;

    ul{
    margin:0;
    padding:0;
    li{
      color: $greyText;
      list-style-type: none;
      font-weight: 600;
      border-radius:5px;
      padding: 0.7rem;
      font-size:1rem;
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;

    span{
        margin: 4px;
        cursor: pointer;
      }
        
      &:active{
        background: $greyLighter;

        span{
          margin-left: 0.7rem;
        }
      }
      &:hover{
        color: white;
      }
    }
  }
  }

  .library{
    display: flex;
    flex-direction: column;
    background: $greyLight;
    margin: 3px;
    border: 2px solid $greyLight;
    border-radius: 10px;
    margin-top: 0.4rem;
    height: 77.7%; //5

    .header-lab{
      display: flex;
      margin: 0.7rem;

      .lab-collapse{
        margin-right: auto;


      }
      
      li{
        list-style: none;
        color: #b3b3b3;
        cursor: pointer;
      }

      
    }

    .playlist-scroll {
      flex: 1;
      overflow-y: scroll;
      background: $greyLight;
      height: 20%;
      margin: 7px;
      color: #fffbfb;

      .playlist{
        background: $greyLighter;
        margin: 3px;
        border: 2px solid $greyLight;
        border-radius: 7px;
        margin-top: 0.4rem;
        padding: 2px;
        height: fit-content;

        h4{
          margin:10px;
        }

        .playlist-button{
          margin:10px;

          p{
            font-size: 12px;
          }
          
          .buttonStyle{
            background: white;
            border: 2px solid white;
            border-radius: 20px;
            font-size: 14px;
            font-weight: bold;
            width: max-content;
            height: 34px;
            margin-top: 0.4rem;
            cursor: pointer;

          }
        }
      }

    }

        /* Hide the default scrollbar */
        .playlist-scroll::-webkit-scrollbar {
          width: 8px;
          /* Adjust the width as needed */
        }
    
        .playlist-scroll::-webkit-scrollbar-thumb {
          background: transparent;
          /* Hide the thumb */
        }
    
        /* Show the scrollbar when scrolling */
        .playlist-scroll:hover::-webkit-scrollbar-thumb {
          background: #8a8585;
          /* Change the thumb color as needed */
          //border-radius: 5px;
        }
    
        .playlist-scroll::-webkit-scrollbar-track {
          background-color: transparent;
          /* Hide the track */
        }
    
        .playlist-scroll:hover::-webkit-scrollbar-track {
          background-color: transparent;
          /* Change the track color as needed */
        }

    .cookies {
      font-size: 0.7rem;
      color: $greyText;
      padding: 0.7rem;
      padding-bottom: 29px;
    
      span {
        display: block;
        //text-align: center;
        cursor: pointer;
    
      }
    
      .policies {
        display: flex;
        padding-bottom: 19px;
      }
    
      .policy {
        margin-right: 20px;
      }
    
      .abouts {
        display: flex;
        padding-bottom: 19px;
      }
    
      .about {
        margin-right: 20px;
      }
    }
    
    .button {
      padding: 0.7rem;
    
      .buttonStyle {
        background: $greyLight;
        color: white;
        width: max-content;
        padding: 0.7rem;
        border: 2px solid $greyLighter;
        border-radius: 23px;
        cursor: pointer;
      }
    }
  }
  
}

.main{
  width: 72.7%;
  height: 97%;
  overflow-y:scroll;
  position: relative;
  padding-top:3rem;

  margin: 7px;
  margin-bottom: 8.4px;
  background: $greyLight;
  border-radius: 10px;
  
  
  .upperNav{
    background: rgba(9,9,9, 0.78);
    height: 3.7rem;
    color: #b3b3b3;
   // margin: 3px;
    width:72.7%;
    display:flex;
    justify-content: space-between;
    align-items: center; 
    padding:0.5rem 2rem;
    position:fixed;
    top:0;
    z-index:2;

    .upperNavLeft{
      width: 2rem;
      display:flex;

      span{
        width:30px;
        height: 20px;
        color:#b3b3b3;
        cursor: pointer;
      }
    }

    .upperNavRight{
      display: flex;
      margin: 0.4rem;

      .support{
        display: flex;
        margin-right: 2.9rem;
        align-items: center;

        li{
          list-style:none;
          margin: 4.4px;
          cursor: pointer;
        }


      }

      .accounts{
        display: flex;
        margin: 3px;
        padding : 0.7rem;
        align-items: center;

        li{
          list-style: none;
          cursor: pointer;
          margin-right: 0.7rem;

          Link{
            color: #b3b3b3;
            
          }
        }

        .btn-signUp{
          margin-right: 1.7rem;
        }

        .btn-login{
          cursor: pointer;
          background: white;
          border: 1px solid white;
          border-radius: 20px;
          width: 89px;
          height: 40px;
        }
      }

      
    }

    @media (max-width: 768px) {
      .upperNav{
        .upperNavRight{
          .support{
            display: none;
          }

          .hamburger-menu {
            display: block; /* Show the hamburger menu */
            width:70px;
          
            .icon {
              cursor: pointer;
              padding: 10px;
              color: #fff;
            }

            .dropdown {/* Style for the dropdown menu */
              display: none;
              position: absolute;
              background-color: #fff;
              border: 1px solid #ccc;
              padding: 10px;
              top: 40px;
              right: 0;

              ul{
                list-style-type:none;
              }
            }


          }
        }
      } 

      
       
    }
  }

  .mainContent{
    flex: 1;
    background: $greyLight;
    padding: 0.1rem 1rem;
    height:27rem;

    .cardsWraps{
      h1 {
        font-size: 1.4rem;
        color: #fffbfb
      }

      .seeall{
        margin-left:54rem;
        color: #b3b3b3;
        font-weight: 500;
      }
      .cardsWrap{
        display:grid;
        grid-gap:1px;
        grid-template-columns: repeat(auto-fill, minmax(164px,1fr));

        .card{
          background: $greyLighter;
          border-radius: 10px;
          overflow: hidden;
          padding: 0.6rem;
          box-shadow: 0 10px 30px 0 rgba(0,0,0.3), 
            0 1px 2px rgba(0,0,0.2);
          position: relative;
          margin-right:0.7rem;
          
          &:last-child {
            .card{
              margin-right: 0;
            }   
          }

          &:hover{
            .playIcon{
              opacity: 1;
            }
          }
          .cardImage{
            height: 170px;
            box-shadow: 0 10px 30px 0 rgba(0,0,0.3), 
            0 1px 2px rgba(0,0,0.2);
            border-radius: 8px;
            img{
              width:100%;
              height: 100%;
              object-fit: cover;
              border-radius: 8px;
            }
          }

          .cardContent{
            padding: 0.4rem 0;
            h3 {
              font-weight:700;
              font-size: 0.9rem;
              color: #fffbfb;
              margin-bottom:0;
              letter-spacing:0.7px;
            }

            p{
              font-size: 0.8rem;
              color: #fffbfb;
            }
          }

          .playIcon{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: $greenMain;
            overflow: hidden;
            display: flex;
            //display: none;
            opacity: 0;
            position: absolute;
            right: 1.4rem;
            bottom: 9rem;

            img{
              margin: 10px auto;
            }
          }
        }
      }
    } 

    .theFooter{
      display:flex;
      flex-direction:column;
      margin-top: 90px;
      justify-content: space-between;

      .links{
        display:flex;
        color: white;
        flex-wrap: wrap;

        .company{
          //margin-right:1.3rem;
          align-items: left;
          
          
         /* &:last-child {
            .company{
              margin-right: 0;
            }   
          } */

          h1{
            font-size: medium;
            text-align: center;
          }

          li{
            list-style: none;
            text-align: left;
            padding:7px;
          }

        }

         @media (max-width: 768px) {
            .company {
                flex: 0 0 calc(100% - 20px);
                    /* Display in 1 columns on smaller screens */
            }
        
            .socialMedia {
                display: flex;
                margin-top: 20px;
                    /* Show social media section on smaller screens */
            }
          }
      }

      .socialMedia {
          display: flex;
          justify-content: space-between;
          width: 200px;/* container's width */
          margin-left: 9.9rem;

          .social-media-button {
              width: 40px;/*  button's width */
              height: 40px;/*  button's height */
              background-color: #2b2a2a;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              font-size: 20px;
              transition: background-color 0.3s ease;
            }

            .social-media-button:hover {
              background-color: #4e4f50;/*  hover background color */
            }
      }

      .copyright{
        text-align: left;
        margin-top: 30px;
        margin-bottom: 70px;
        color: #b3b3b3;
        border-top: 1px solid #b3b3b3;

        span{
          padding-top: 30px;
        }
      }
    }
  }
}

 /* Hide the default scrollbar */
 .main::-webkit-scrollbar {
   width: 12px;
   /* Adjust the width as needed */
 }

 .main::-webkit-scrollbar-thumb {
   background: transparent;
   /* Hide the thumb */
 }

 /* Show the scrollbar when scrolling */
 .main:hover::-webkit-scrollbar-thumb {
   background: #d1cfcf;
   /* Change the thumb color as needed */
   //border-radius: 5px;
 }

 .main::-webkit-scrollbar-track {
   background-color: transparent;
   /* Hide the track */
 }

 .main:hover::-webkit-scrollbar-track {
   background-color: transparent;
   /* Change the track color as needed */
 }

.outerWrap{
  background: grey;
  height: 100vh; 
  min-height: 100%;
}

.musicContols{
  background: $greyDark;
  text-align: center;
  height: 90px;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: white;
  //z-index: 2;
  //position: relative;

  .controls{
    display: flex;
    margin-left: 8px;
    padding: 4px;
    width: 98.7%;
    height: 80px;
    background: linear-gradient(to right, #ff33cc8e,
      #348ec2f5);
      
    .prewiew{
      width: 79%;
      float: left;
      text-align: justify;
      padding-left: 9px;
      
      h1{
        font-size: xx-small;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-weight: 20;
        cursor: pointer;
      }

      span{
        font-size: 12px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        cursor: pointer;
      }
    }

    .signup-btn{
    width:19rem;
    margin: 17px;
    padding-left: 89px;

      button{
        width: 100px;
        height: 40px;
        border-radius: 20px;
        border: white solid ;
        font-weight: 600;
        font-size:14px;
        cursor: pointer;
      }
    }
  }
 
} 

.sign-up {
  text-align: center;
  max-width: 400px;
  margin: 20rem auto;
  //padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;

  .logo {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  .socials {
    margin-bottom: 20px;
  }

  .social-button {
    width: 70%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }

  .facebook {
    background-color: #1877f2;
    color: #fff;
  }

  .google {
    background-color: #4285f4;
    color: #fff;
  }

  .divider {
    margin-bottom: 20px;
    
  }

  .section-title {
    text-align: left;
    font-size: 1rem;
    margin-bottom: 40px;
    margin-top: 20px;
    margin: 3px;
  }

  .input-container {
    text-align: left;
    margin-bottom: 20px;
  }

  label {
    font-size: 0.9rem;
    font-weight: bold;
  }

  .input-text {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }

  .terms {
    font-size: 0.8rem;
    margin-bottom: 20px;
    padding-top: 40px;
  }

  .sign-up-button {
    width: 34%; 
    padding: 10px;
    background-color: #1db954;
    color: #111010;
    border: none;
    border-radius: 20px;
    font-weight: 800;
    font-size: medium;
    cursor: pointer;
  }

  .sign-up-button:hover {
    background-color: #169945;
  }

}

.Search{
  background: $greyLight;
}

